import { css, useTheme } from '@emotion/react'
import { RemoteContext } from 'fitify-ui/src/hooks/useRemoteConfig'
import { CrossIcon } from 'fitify-ui/src/Icon/components/CrossIcon'
import {
  Footer,
  LayoutContent,
  LayoutStack,
  StyledCircleButton,
  SubscriptionPromoInfoBoxStyled,
  toast,
} from 'fitify-ui-onboarding/src/components'
import Header from 'fitify-ui-onboarding/src/components/Header/Header'
import { StyledPromoRedirect } from 'fitify-ui-onboarding/src/components/Header/Header.Styled'
import { useUserContext } from 'fitify-ui-onboarding/src/contexts/UserContext'
import { useBeforeLeaveDialog } from 'fitify-ui-onboarding/src/hooks/useBeforeLeaveDialog'
import { getPaging } from 'fitify-ui-onboarding/src/hooks/useNavigation'
import { usePromo } from 'fitify-ui-onboarding/src/hooks/usePromo'
import { BREAKPOINTS, encodeQuery } from 'fitify-ui-onboarding/src/utils'
import { useRouter } from 'next/router'
import { useTranslation } from 'next-i18next'
import { NextSeo } from 'next-seo'
import { OpenGraph } from 'next-seo/lib/types'
import { CSSProperties, ReactNode, useContext } from 'react'

import { AuthSignupDisclaimer } from 'sections/auth/AuthSignupDisclaimer'
import { getImageProps } from 'utils/images'
import { APP_PAGES, ONBOARDING_PAGES } from 'utils/routes'
import { DataContext } from 'utils/state'

import IconArrowLeftV2 from '../../public/icons/IconArrowLeftV2.svg'

interface LayoutProps {
  seo?: {
    title?: string
    description?: string
    openGraph?: OpenGraph
  }
  options?: {
    isFullscreen?: boolean
    isPrevHidden?: boolean
    isHeaderHidden?: boolean
    hasShadow?: boolean
    hasBackground?: boolean
  }
  layoutStyle?: CSSProperties
  children: ReactNode
  className?: string
}

const SubscriptionPromoInfoBox = () => {
  const { t } = useTranslation()

  return (
    <SubscriptionPromoInfoBoxStyled>
      {t('promo_info_active_title')}
    </SubscriptionPromoInfoBoxStyled>
  )
}

const CircleBackButton = ({
  handleOnPrevClick,
  isPromoActive,
  isPrevHidden,
}: {
  handleOnPrevClick: () => void
  isPromoActive: boolean
  isPrevHidden?: boolean
}) => {
  return (
    <StyledCircleButton
      onClick={handleOnPrevClick}
      isHidden={isPrevHidden}
      data-testid="header-back-button"
      css={css`
        position: absolute;
        top: 14px;
        left: 12px;
        z-index: 100;

        @media ${BREAKPOINTS.MD.gte} {
          top: ${isPromoActive ? '68px' : '24px'};
          left: 24px;
        }
      `}
    >
      <IconArrowLeftV2 />
    </StyledCircleButton>
  )
}

export function Layout({
  options,
  layoutStyle,
  children,
  className,
  seo,
}: LayoutProps) {
  const { t } = useTranslation()
  const router = useRouter()
  const config = useContext(RemoteContext)
  const { data, clear } = useContext(DataContext)

  const promoCode = usePromo()
  const theme = useTheme()
  const { user, logout } = useUserContext()

  const { onboarding_payment_close_promo } = config

  const noLeaveDialogPages = [
    ONBOARDING_PAGES.home.path,
    ONBOARDING_PAGES.subscription.path,
    ONBOARDING_PAGES.success.path,
    APP_PAGES.login.path,
    APP_PAGES.loginEmail.path,
    APP_PAGES.signup.path,
    APP_PAGES.passwordReset.path,
    APP_PAGES.giftSuccess.path,
    '/404',
    '/500',
  ]

  useBeforeLeaveDialog(!noLeaveDialogPages.includes(router.route))

  // Theme variants
  const isDigitalV2 = theme.digitalV2
  const isDigitalV4 = theme.digitalV4
  const isHeaderPrevHidden = !isDigitalV2 ? options?.isPrevHidden : true

  const noPromoPages = [
    ONBOARDING_PAGES.subscription.path,
    ONBOARDING_PAGES.success.path,
    APP_PAGES.gift.path,
    APP_PAGES.giftSuccess.path,
    APP_PAGES.redeem.path,
    APP_PAGES.redeemSuccess.path,
    '/404',
    '/500',
  ]

  const isPromoActive =
    promoCode !== null && !noPromoPages.includes(router.route)
  const isSubscriptionScreen =
    router.route === ONBOARDING_PAGES.subscription.path

  const handleOnPrevClick = () => {
    const paging = getPaging({
      path: router.pathname,
      data,
    })

    if (paging?.prev) {
      router.replace(paging.prev.path)
    } else {
      router.back()
    }
  }

  const handleLogout = async () => {
    try {
      await logout()
      clear() // clear state and promo

      router.push(ONBOARDING_PAGES.home.path)
    } catch (err) {
      console.error(err)
      toast({ type: 'error', content: 'Failed to log out' })
    }
  }

  const handleRedirectToPromo = () => {
    router.push(
      encodeQuery(ONBOARDING_PAGES.subscription.path, { promo: 'sweat30' })
    )
  }

  const logo =
    isDigitalV2 || isDigitalV4
      ? getImageProps('common', 'logo')
      : getImageProps('common', 'logoDark')

  const closeButton =
    promoCode === null &&
    isSubscriptionScreen &&
    onboarding_payment_close_promo ? (
      <StyledPromoRedirect isHidden={false} onClick={handleRedirectToPromo}>
        <CrossIcon />
      </StyledPromoRedirect>
    ) : null

  const isFooterShown = router.route === ONBOARDING_PAGES.home.path

  return (
    <>
      <NextSeo
        title={seo?.title}
        description={seo?.description}
        openGraph={seo?.openGraph}
      />
      {!options?.isHeaderHidden && (
        <Header
          components={{
            logo: logo,
            closeButton: closeButton,
          }}
          data={{
            user: user,
            promo: promoCode,
            logoutText: t('settings_logout_title'),
          }}
          actions={{
            handleLogout,
            handleOnPrevClick,
          }}
          options={{
            isPrevHidden: isHeaderPrevHidden,
          }}
        />
      )}
      <LayoutStack orientation="horizontal" xs="5.125rem">
        <LayoutContent
          isFullscreen={options?.isFullscreen}
          isPromoActive={isPromoActive}
          hasShadow={options?.hasShadow}
          hasBackground={options?.hasBackground}
          style={layoutStyle}
          className={className}
        >
          {isDigitalV2 && (
            <CircleBackButton
              handleOnPrevClick={handleOnPrevClick}
              isPromoActive={isPromoActive}
              isPrevHidden={options?.isPrevHidden}
            />
          )}

          {options?.isPrevHidden}

          {isPromoActive && <SubscriptionPromoInfoBox />}
          {children}

          {isFooterShown && <Footer disclamer={<AuthSignupDisclaimer />} />}
        </LayoutContent>
      </LayoutStack>
    </>
  )
}
